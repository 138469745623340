import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ProgressService } from 'src/app/services/progress.service';
import { map, tap } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Arabic } from 'flatpickr/dist/l10n/ar';

declare let moment: any;
declare let $: any;

@Component({
  selector: 'admin-layout-receptionsts-transactions',
  templateUrl: './receptionsts-transactions.component.html',
  styleUrls: ['./receptionsts-transactions.component.scss']
})
export class ReceptionstsTransactionsComponent implements OnInit {
  total = 0;
  rec: any;
  isExcel: boolean = false;
  transaction: boolean = false;
  transactions: any;
  request: boolean = false;
  rev: boolean = true;
  requests: Array<any> = [];
  pagination: any;
  pagination_transactions: any = {};
  action: any = {};
  from = null;
  to = null;
  from_time = null;
  to_time = null;
  payment_status = null;
  patient_id = null
  correspondent_id = null
  id = null;
  payment_method_filter = '';
  cashier_id_filter = '';
  payer = '';
  user_id = ''
  isTax = false;
  patients: any = [];
  correspondents: any = [];
  payment_methods: any = [];
  cashiers: any = [];
  withTests = false;
  excel = false;
  excel2 = false;
  dropdownSettings: IDropdownSettings;
  dropdownSettings2: IDropdownSettings;
  users: any[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    public data: DataService,
    public router: Router,
    public api: ApiService,
    public progress: ProgressService
  ) {}


  ngOnInit() {
    this.from = moment().subtract(7, 'days').format('YYYY-MM-DD');
    this.to = moment().format('YYYY-MM-DD');
    this.from_time = '00:00';
    this.to_time = '23:59';
    this.getUsers();
    this.getPaymentMethods();
    this.getCashier();
    this.getTransactions(1);
  }


  getUsers() {
    this.api.Admin.users.get().subscribe((res) => {
      this.users = res['data'];
    });
  }

  print_transaction(id = this.id, init = false) {
    let excel2 = this.excel2 == true ? 1 : 0;
    setTimeout(
      () =>
        this.api.Common.printables.receptionists
          .allTransaction(
            `?min_date=${this.from
              .split('-')
              .reverse()
              .join('-')}&max_date=${this.to
              .split('-')
              .reverse()
              .join('-')}&from_time=${this.from_time}&to_time=${
              this.to_time
            }&excel=${excel2}&payment_method_id=${this.payment_method_filter}&payer=${this.payer}&cashier_id=${this.cashier_id_filter}&user_id=${this.user_id}`
          )
          .subscribe((res) => {
            const fileURL = URL.createObjectURL(res);
            window.open(fileURL, '_blank');
            console.log('hello people');
          }),
      0
    );
  }

  sendRequest() {
    // this.rev ? this.getReceptionist() : '';
    // this.request ? this.getRequests() : '';
    // this.transaction ? this.getTransactions() : '';
    this.getTransactions();
  }

  getPaymentMethods(){
    this.api.Common.Get.payment_methods().subscribe((res) => {
      this.payment_methods = res;
    })
  }
  
  getCashier(){
    this.api.Common.Get.treasuries().subscribe((res) => {
      this.cashiers = res;
    })
  }

  getTransactions(page = 1) {
    setTimeout(
      () =>
        this.api.Admin.receptionists
          .allTransactions({
            min_date: this.from.split('-').reverse().join('-'),
            max_date: this.to.split('-').reverse().join('-'),
            from_time: this.from_time,
            to_time: this.to_time,
            payment_method_id: this.payment_method_filter,
            cashier_id: this.cashier_id_filter,
            payer: this.payer,
            user_id: this.user_id,
            page,
          })
          .subscribe((res) => {
            this.transactions = res['data'];
            this.total = res['total_transactions'];
            this.pagination_transactions = this.data.extract_pagination(res);
          }),
      0
    );
  }

}
