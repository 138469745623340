<div class="row">
  <div class="col">
    <h2 class="start roboto-300">
      {{ (action  + ' Request') | locale}} {{ requestNum ? "#" + requestNum : "" }}
    </h2>
    <h5 class="text-muted start" *ngIf="request?.created_at">
      {{ request.created_at | date: "full" }}
    </h5>
  </div>

  <div *ngIf="requestNum && hasActivityLog">
    <div>
      <button
        data-toggle="modal"
        data-target="#param-modal"
        type="button"
        id="add-button"
        class="btn btn-primary mx-2 roboto-400"
        (click)="
          data.activity_log_request_id_stored = requestNum;
          data.navigate_to('receptionist/activity', { abs: true })
        "
      >
        {{'Activity log' | locale}}
      </button>

    </div>
  </div>
  <div>
    <div>
      <button
        type="button"
        id="add-button"
        class="btn btn-primary mx-2 roboto-400"
        (click)="backWithData()"
      >
        {{'Back' | locale}}
      </button>
    </div>
  </div>
  <div *ngIf="patient.hospital_id != null" >
    <div>
      <button type="button" id="add-button" class="btn btn-primary mx-2 roboto-400" (click)="history()"> {{'history' | locale}} </button>
    </div>
  </div>

</div>

<div class="card w-100 p-4 p-xl-5 my-5">
  <div class="container-fluid py-2">
    <div *ngIf="action == 'New'" class="row justify-content-end">
      <div
        *ngFor="let button of buttons"
        style="text-align: end"
        class="col px-2"
      >
        <button
          *ngIf="lab_to_lab"
          (click)="download_upload_modal_data = button.data"
          data-target="#download_upload_modal"
          data-toggle="modal"
          class="btn btn-outline-primary"
        >
          {{ button.label | locale }}
        </button>
      </div>
    </div>

    <div class="col">
      <h4 class="roboto-500 start">{{'Patient Info' | locale}}</h4>
    </div>

    <ng-container *ngIf="action == 'New'">
      <div class="container-fluid px-4">
        <div class="row mt-4">
          <div class="col-12">
            <h5 class="roboto-500 start">{{'Select a patient' | locale}}</h5>
          </div>
        </div>
      </div>

      <receptionist-layout-patient-select
        *ngIf="!patient.name"
        (setSelectedPatient)="setSelectedPatient($event)"
      ></receptionist-layout-patient-select>
      <div class="col-12 mt-3 form-group">
        <ng-container *ngIf="patient.name">
          <div class="col-12 container-fluid">
            <div class="row">
              <div class="col">
                <button class="btn btn-light border col right-rad">
                  {{ patient.name }}
                </button>
              </div>
              <div>
                <button
                  class="btn btn-danger form-control left-rad"
                  style="width: fit-content"
                  (click)="patient = {}"
                >
                  ✖
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <div [ngClass]="{ disabledbutton: patient.name && action == 'New' }">
      <receptionist-layout-new-patient
        [patient]="patient"
        [isCreate]="false"
        [action]="{ type: 'New' }"
      ></receptionist-layout-new-patient>
    </div>
  </div>

  <form #form="ngForm">
    <div class="container-fluid">
      <div class="row mt-2 mb-4">
        <div class="col-12">
          <h4
            class="roboto-500 py-2 start"
            [ngClass]="{
              'rtl':data.isRTL
            }"
          >
            {{'Health Certificate' | locale}}
          </h4>
        </div>
      </div>
      <div class="row neue-reg">
        <div class="col-lg-6">
          <div class="form-group">
            <label for="civilitysqa">{{'Identity Type' | locale}}</label>
            <select
              *ngIf="identity_types"
              [(ngModel)]="request.identity_type_id"
              name="identity_type_id"
              id="civilitysqa"
              class="custom-select"
            >
            <option [value]="'0'">{{'None' | locale}}</option>
            <option
                *ngFor="let identity_type of identity_types"
                [value]="identity_type.id"
              >
                {{ ''+identity_type.name+'' | locale}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="exampleInputEmail1q">{{'Identity Number' | locale}} </label>
            <input
              name="identity_type_number"
              [(ngModel)]="request.identity_type_number"
              type="text"
              class="form-control"
              #identity_type_number_input="ngModel"
              [placeholder]="'Identity Number' | locale"
            />
          </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group">
            <label for="exampleInputEmail1q">{{'Requested Correspondent' | locale}} </label>
            <input
              name="requested_correspondent"
              [(ngModel)]="request.requested_correspondent"
              type="text"
              class="form-control"
              #requested_correspondent_input="ngModel"
              [placeholder]="'Requested Correspondent' | locale"
            />
          </div>
        </div>
      </div>
      <div class="row mt-2 mb-4">
        <div class="col-12">
          <h4
            id="info_collapse"
            data-toggle="collapse"
            [attr.data-target]="action === 'New' ? null : '#info'"
            aria-expanded="false"
            aria-controls="info"
            class="roboto-500 py-2 start"
            [ngClass]="{
              'dropdown-toggle p-3 btn-link clickable': action === 'Edit',
              'rtl':data.isRTL
            }"
          >
            {{'Request Info' | locale}}
          </h4>
        </div>
      </div>
      <div id="info" class="collapse" class="show">
        <!-- <div id="info" class="collapse" [ngClass]="{'show': action === 'New'}"> -->
        <div class="row neue-reg">
          <div class="col-lg-4">
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="civilitys">{{'Correspondent' | locale}}</label>

                  <!-- *ngIf="correspondents" -->
                  <select
                    *ngIf="correspondents | async as corres"
                    [(ngModel)]="request.correspondent_id"
                    name="correspondent_id"
                    id="civilitys"
                    class="custom-select"
                    (change)="change_pricing($event.target.value, corres)"
                  >

                  <!-- *ngFor="let correspondent of correspondents" -->
                  <option [value]="'0'">{{'None' | locale}}</option>
                  <option
                      *ngFor="let correspondent of corres"
                      [value]="correspondent.id"
                      [selected]="correspondent.is_default"
                    >
                      {{ correspondent.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="civi">{{'Main Correspondent' | locale}}</label>
                  <!-- *ngIf="subcategories" -->
                  <select
                    *ngIf="subcategories | async as subcorres"
                    [(ngModel)]="subcategories_id"
                    name="subcategories_id"
                    id="civi"
                    class="custom-select"
                    (change)="change_subcategory($event.target.value, subcorres)"
                    >
                    <!-- (change)="change_subcategory($event.target.value, subcategories)" -->
                    <option [value]="'0'">{{'None' | locale}}</option>
                    <!-- *ngFor="let subcategory of subcategories" -->
                    <option
                      *ngFor="let subcategory of subcorres"
                      [value]="subcategory.id"
                    >
                      {{ subcategory.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <ng-container *ngIf="userUnits">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="civi">{{'Units (use ctrl)' | locale}}</label>
                      <!-- *ngIf="subcategories" -->
                      
                      <!-- [(ngModel)]="unit_id" -->
                      <select
                        multiple [(ngModel)]="selectedItems"
                        (change)="showSelectedItems()"
                        name="unit_ids"
                        id="civia"
                        class="custom-select"
                        size = 2
                        >
                        <!-- <option [value]="'0'">{{'None' | locale}}</option> -->
                        <option
                          *ngFor="let unit of units"
                          [value]="unit.id"
                        >
                          {{ unit.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="picker">{{'Hospitalization Date' | locale}}</label>
                      <input
                        name="hospitalization_date"
                        [(ngModel)]="request.hospitalization_date"
                        id="picker"
                        class="flatpickr flatpickr-input active form-control"
                        type="text"
                        [placeholder]="'Select a Date' | locale"
                      />
                    </div>
                  </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!userUnits">
              <div class="form-group">
                <label for="picker">{{'Hospitalization Date' | locale}}</label>
                <input
                  name="hospitalization_date"
                  [(ngModel)]="request.hospitalization_date"
                  id="picker"
                  class="flatpickr flatpickr-input active form-control"
                  type="text"
                  [placeholder]="'Select a Date' | locale"
                />
              </div>
            </ng-container>
          </div>
          <div class="col-lg-4">
            <label style="display: block;text-align: start;">{{'Doctor' | locale}}</label>

            <ng-container *ngIf="action == 'Edit'">
              <ng-container *ngIf="!doctor" >
              
                <ng-container *ngIf="cantChangeDoctor">
                  <selector
                      [no_label]="true"
                      [attr.disabled]="cantChangeDoctor"
                      top="67px"
                      (setSelectedItem)="doctor = $event"
                      title="doctors"
                    ></selector>
                  </ng-container>
                <ng-container *ngIf="!cantChangeDoctor">
                  <selector
                      [no_label]="true"
                      top="67px"
                      (setSelectedItem)="doctor = $event"
                      title="doctors"
                    ></selector>
                  </ng-container>
              </ng-container>
              <ng-container *ngIf="doctor">
                <ng-container *ngIf="cantChangeDoctor">
                  <button  class="btn btn-light border col-12">{{doctor.name}}</button>
                </ng-container>
                <ng-container *ngIf="!cantChangeDoctor">
                  <button (click)="doctor = null" class="btn btn-light border col-12">{{doctor.name}}</button>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="action == 'New'">
              <ng-container *ngIf="!doctor" >
                <selector
                [no_label]="true"
                top="67px"
                (setSelectedItem)="doctor = $event"
                title="doctors"
              ></selector>
              </ng-container>
              <ng-container *ngIf="doctor">
                <button (click)="doctor = null" class="btn btn-light border col-12">{{doctor.name}}</button>
              </ng-container>
            </ng-container>

            <!-- <div class="form-group">
              <label for="civility">Doctor</label>
              <select
                [(ngModel)]="request.doctor"
                name="doctor"
                id="civility"
                class="custom-select"
              >
                <option value="">None</option>
                <option *ngFor="let doctor of doctors" [value]="doctor.id">
                  {{ doctor.name }}
                </option>
              </select>
            </div> -->
          </div>
        </div>
        <div class="row neue-reg">
          <div *ngIf="pricings" class="col-lg-4">
            <div class="form-group">
              <label for="pricing">{{'Pricing' | locale}}</label>
              <ng-container *ngIf="cantChangePrice">
                <select
                  (change)="changeTestsPricing()"
                  [(ngModel)]="request.pricing_id"
                  [attr.disabled]="cantChangePrice"
                  name="pricing_id"
                  id="pricing"
                  class="custom-select"
                  [ngClass]="{'sel-dis' : cantChangePrice}"
                >
                  <option
                    *ngFor="let pricing of pricings | async"
                    [value]="pricing.id"
                  >
                    {{ pricing.name }}
                  </option>
                </select>
              </ng-container>

              <ng-container *ngIf="!cantChangePrice">
                <select
                  (change)="changeTestsPricing()"
                  [(ngModel)]="request.pricing_id"
                  name="pricing_id"
                  id="pricing"
                  class="custom-select"
                >
                  <option
                    *ngFor="let pricing of pricings | async"
                    [value]="pricing.id"
                  >
                    {{ pricing.name }}
                  </option>
                </select>
              </ng-container>
              
            </div>
          </div>

          <div class="col-lg-4">
            <div class="form-group">
              <label for="in_patient">{{'In | Out Patient' | locale}}</label>
              <select
                [ngModel]="request.in_patient ? 1 : 0"
                name="in_patient"
                id="in_patient"
                class="custom-select"
              >
                <option [value]="0">{{'Out Patient' | locale}}</option>
                <option [value]="1">{{'In Patient' | locale}}</option>
              </select>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="form-group">
              <label for="chargeTo">{{'Charge to' | locale}}</label>
              <!--
              PLZ DON'T DELETED AHMED PLZ
              <select

              [ngModel]="request.isClinic?1:0"
              name="isClinic"
              id="chargeTo"
              class="custom-select"
            >
              <option
                [value]="0"
              >
                Patient or/and Correspondent
              </option>
              <option
                [value]="1"
              >
              Clinic
            </option>
            </select> -->
              <input
                class="form-control"
                name="isClinic"
                [value]="(clinic ? 'Clinic' : 'Patient or | and Correspondent') | locale"
                disabled
              />
            </div>
          </div>
          <!-- <div class="col-lg-3">
            <div class="form-group">
              <label for="exampleInputEmail1">Hospital ID </label>
              <input type="button" value="search">
              <button (click)="history()" [hidden]="request.hospital_id.length <= 3">     <i  class="fas  fa-search"></i> </button>

              <input
                maxlength="25"
                placeholder="Enter Hospital id"
                [(ngModel)]="request.hospital_id"
                name="hospital_id"
                type="text"
                class="form-control"
                #hospital_id_input="ngModel"
              />
            </div>
          </div> -->
        </div>
        <div class="row neue-reg">
          <div class="col-lg-12">
            <div class="form-group">
              <label for="exampleInputEmail1">{{'Notes | Clinical Information' | locale}} </label>
              <input
                maxlength="500"
                name="clinical_information"
                [(ngModel)]="request.clinical_information"
                type="text"
                class="form-control"
                #clincal_information_input="ngModel"
                [placeholder]="'Notes | Clinical Information' | locale"
              />
            </div>
          </div>
        </div>

        <div class="row neue-reg">
          <div class="col-lg-12">
            <div class="form-group">
              <label for="exampleInputEmail1">{{'Laboratory Notes' | locale}}</label>
              <textarea
              (input)="showWSaveLabNotes()"
                name="laboratory_information"
                rows="4"
                cols="50"
                [(ngModel)]="request.laboratory_information"
                type="text"
                class="form-control"
                #lab_input="ngModel"
                [placeholder]="'Laboratory Notes' | locale"
              ></textarea>
              <div class="">
                <button
                  *ngIf="request?.status == 'technically validated'"
                  (click)="saveLabNotes(lab_input)"
                  [hidden]="isLabNotesSaveHidden"
                  #save
                  type="submit"
                  class="btn btn-success py-0 roboto-400 btn-lab-notes"
                  [ngClass]="{'save-btn' : !data.isRTL, 'save-btn-rtl': data.isRTL}"
                >
                  {{'Save' | locale}}
                </button>
              </div>
            </div>



          </div>
        </div>

        <div class="row">
          <div class="col-lg-3">

            <div class="d-flex h-100 align-items-center start">

              <div class="form-group form-check">
                <input
                name="urgent_flag"
                [(ngModel)]="request.urgent_flag"
                  type="checkbox"
                  class="form-check-input"
                  id="customCheck1"
                />
                <label class="form-check-label" [ngClass]="{'mr-4' : data.isRTL}" for="customCheck1">{{'Urgent' | locale}}</label>
              </div>

            </div>
          </div>
          <div class="col-lg-3">
            <div class="d-flex h-100 align-items-center start">

              <div class="form-group form-check">
                <input
                name="is_repeat"
                [(ngModel)]="request.is_repeat"
                  type="checkbox"
                  class="form-check-input"
                  id="customCheck2"
                />
                <label class="form-check-label" [ngClass]="{'mr-4' : data.isRTL}" for="customCheck2">{{'Repeat' | locale}}</label>
              </div>

            </div>
          </div>
          <div class="col-lg-3">
            <div class="d-flex h-100 align-items-center start">

              <div class="form-group form-check">
                <input
                name="is_whatsapp"
                [(ngModel)]="request.is_whatsapp"
                  type="checkbox"
                  class="form-check-input"
                  id="customCheck2"
                />
                <label class="form-check-label" [ngClass]="{'mr-4' : data.isRTL}" for="customCheck2">{{'Whatsapp' | locale}}</label>
              </div>

            </div>
          </div>
        </div>

        <hr class="mt-4" />

        <div class="row mt-4 mb-4">
          <div class="col-12">
            <h4 class="roboto-500 start">{{'Add Tests | Profiles' | locale}}</h4>
          </div>
        </div>

      <!-- <selector
      title="test"
      >

      </selector> -->

        <receptionist-layout-test-select
          [requestId]="request_id"
          (setSelectedTest)="changeTestsPricing($event)"
          unique="_selection"
        ></receptionist-layout-test-select>


        <div
          class="table-responsive mt-2"
        >
          <table class="table">
            <thead>
              <tr class="neue-light">
                <!-- <th  scope="col" > الرقم التسلسلي</th> -->
                <th scope="col" style="width: 20px;">{{'Id' | locale}}</th>
                <th style="width: 30px" scope="col">{{'Code' | locale}}</th>
                <th style="width: 300px" scope="col">{{'Name' | locale}}</th>
                <th style="width: 20px" scope="col">{{'Profile' | locale}}</th>
                <th scope="col" style="width: 20px;">{{'Original' | locale}}</th>
                <th *ngIf="!clinic" scope="col" style="width: 20px;">{{'Discount' | locale}}</th>
                <th *ngIf="clinic" scope="col">{{'Clinic' | locale}}</th>
                <th *ngIf="isTax" style="width: 20px;" scope="col">{{'Tax' | locale}}</th>
                <th scope="col" style="width: 20px;">{{'Charged' | locale}}</th>
                <ng-container *ngIf="data.me?.sub_roles?.indexOf('deleteTestFromRequest') != -1">
                  <th style="width: 20px;">{{'Remove' | locale}}</th>
                </ng-container>
                <th style="width: 300px;">{{'Note' | locale}}</th>
                <!-- <th scope="col"> Birthdate</th> -->
                <!-- <th scope="col">خيارات</th> -->
                <!-- <th scope="col"></th> -->
              </tr>
            </thead>

            <tbody class="neue-light">
              <ng-container *ngFor="let test of selectedTests; index as i">
                <tr
                  valign="middle"
                  (mousedown)="$event.preventDefault()"
                  class="clickable_row"
                  [ngClass]="{'is_repeat':request.is_repeat && test.is_repeat}"
                >
                  <!-- <td scope="row">{{voucher.serial_number}}</td> -->
                  <td style="width: 20px;">{{ i + 1 }}</td>
                  <td style="width: 30px">{{ test.code }}</td>
                  <td style="width: 300px">{{ test.name }}</td>
                  <td style="width: 20px">{{ test.profile?.name || '❌' }}</td>
                  <td style="width: 20px;">{{( test.price?.amount || test.amount || 0) + data.info['currency']}} </td>
                  <td *ngIf="!clinic" style="width: 20px;">
                    {{ (test.price?.discount || test.discount || 0 )+ data.info['currency']}}
                  </td>
                  <td *ngIf="clinic">
                    {{ (test.price?.clinic_price || test.clinic_price || 0) + data.info['currency']}}
                  </td>
                  <td *ngIf="isTax" style="width: 20px;">
                    {{ test.price?.tax || test.tax || 0}} %
                  </td>
                  <td style="width: 20px;">
                    {{
                      (test.price?.lab_price ||
                        test.lab_price || 0) + data.info['currency']
                    }}
                    <!-- || test.price?.amount || test.amount -->

                  </td>
                  <ng-container *ngIf="data.me?.sub_roles?.indexOf('deleteTestFromRequest') != -1">
                    <td style="width: 20px;" *ngIf="confirmDeleteTest == false || confirmDeleteTest == 'false'">
                      <button
                        (click)="
                          removeTest(
                            test,
                            test.price?.lab_price ||
                              test.lab_price ||
                              test.price?.amount || test.amount
                          )
                        "
                        type="button"
                        class="btn btn-outline-danger"
                      >
                        <i class="fas fa-times"></i>
                      </button>

                    </td>
                    <td style="width: 20px;" *ngIf="confirmDeleteTest == true || confirmDeleteTest == 'true'">
                      <button
                        (click)="openReasonModal(
                          test,
                          test.price?.lab_price ||
                            test.lab_price ||
                            test.price?.amount || test.amount)"
                        type="button"
                        class="btn btn-outline-danger"
                      >
                        <i class="fas fa-times"></i>
                      </button>

                    </td>
                  </ng-container>

                  <td style="width: 300px; text-align: left; overflow-y: scroll;">
                    <pre style="margin-top: 15px; width: 300px;">{{test.active_note}}</pre>
                  </td>
                  <!-- <td>{{patient.birthdate | date}}</td> -->
                  <!-- <td class="flex-column "  ><a class="py-0" ><h5 class="neue-reg text-right">
                  <i (click)="pay(order.id)" *ngIf="order.status == 'بانتظار الدفع'" class="fas fa-dollar-sign item"></i>
                </h5></a>
                </td> -->
                  <!-- <td width="15%"><button [disabled]="!(order.order_state_id == 1 || order.order_state_id == 2) " (click)="cancelOrder(order.id)" class=" btn btn-outline-secondary " > الغاء الطلب</button></td> -->
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>

        <div class="row neue-reg my-4">
          <div class="col-6 start">
            <h4>
              {{'Total charged' | locale}}: <span class="roboto-700 start">{{ (total || 0) + data.info['currency'] }}</span
              >
              <ng-container *ngIf="data.me?.sub_roles?.indexOf('discount') != -1">
              
              <span
                *ngIf="!clinic"
                (click)="onSubmit(form, { isDiscount: true })"
                class="roboto-700 text-sm h6 clickable text-primary mx-3 start"
                >{{'set discount' | locale}}</span
              >
            </ng-container>
            </h4>
            <h4>
              {{'Remaining' | locale}}: <span class="roboto-700 start">{{ (remaining || 0) + data.info['currency'] }}</span
              >
            </h4>
          </div>
          <div class="col-6">
            <div class="d-flex justify-content-end">
              <!-- *ngSwitchCase="'Edit'" -->
              <ng-container>
                <div
                *ngIf="action == 'New'"
                placement="top"
                  [ngbTooltip]="request?.isClinic? 'This request is charged to a clinic':null"

                >
                <button
                  type="button"
                  [disabled]="request_being_made || request?.isClinic"
                  class="btn btn-outline-primary tajwal-regium px-5 mx-2"
                  (click)="onSubmit(form, { isPay: true })"
                >
                  {{'Pay' | locale}}
                </button>
                </div>
                <!-- (click)="goToHome= true; onSubmit(form)" -->
                
                <div *ngIf="action == 'New'">
                  <div
                  placement="top"
                    [ngbTooltip]="(request?.status == 'technically validated' && !(data.me?.sub_roles?.indexOf('saveWhenVaildated') != -1)) ? ('Sorry you can\'t update validated request' | locale):null"

                  >
                    <button
                    type="button"
                    id="onSubmitButton"
                    [disabled]="((request?.status =='technically validated' || request_being_made) && !(data.me?.sub_roles?.indexOf('saveWhenVaildated') != -1))"

                      class="btn btn-outline-primary tajwal-regium px-5"
                      (click)="onSubmit(form, {})"
                    >
                      {{'Save' | locale}}
                    </button>
                  </div>
                </div>
                <div *ngIf="action == 'Edit'">
                  <div
                  placement="top"
                    [ngbTooltip]="(request?.status == 'technically validated' && !(data.me?.sub_roles?.indexOf('saveWhenVaildated') != -1)) || (!(data.me?.sub_roles?.indexOf('editRequest') != -1) && request_id) ? ('Sorry you can\'t update validated request' | locale):null"

                  >
                    <button
                    type="button"
                    id="onSubmitButton"
                    [disabled]="((request?.status =='technically validated' || request_being_made) && !(data.me?.sub_roles?.indexOf('saveWhenVaildated') != -1)) || (!(data.me?.sub_roles?.indexOf('editRequest') != -1) && request_id)"

                      class="btn btn-outline-primary tajwal-regium px-5"
                      (click)="onSubmit(form, {})"
                    >
                      {{'Save' | locale}}
                    </button>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<ng-container *ngIf="action == 'Edit'">
  <ng-container *ngIf="!(request.status == 'active')">
    <receptionist-layout-result [request]="request"></receptionist-layout-result>
  </ng-container>
</ng-container>

<div
  class="modal fade"
  id="payment_modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="container-fluid px-4">
          <ng-container>
            <receptionist-layout-payment
              #paymentComponent
              [patientId]="this.data.payment_patientId"
              [request_id]="this.data.payment_request_id"
              (refTotal)="refreshTotalAfterPayment($event)"
            ></receptionist-layout-payment>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="history"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="container-fluid px-4">
          <ng-container>
            <h1 class="start">{{'History' | locale}}</h1>
            <div class="row">
              <div class="col-12">
                <div class="table-responsive">
                  <table class="table mt-3" >
                    <thead>
                      <tr class="neue-light roboto-400">
                        <!-- <th  scope="col" > الرقم التسلسلي</th> -->
                        <th scope="col" width="15%">{{'Request' | locale}} #</th>
                        <!-- <th scope="col"> Patient</th> -->
                        <!-- <th scope="col"> Correspondent</th> -->
                        <th scope="col" width="15%">{{'Date' | locale}}</th>
                        <!-- <th scope="col"> Clinical Info</th> -->
                        <!-- <th scope="col" width="15%">Category</th> -->
                        <!-- <th scope="col"> Total</th> -->
                        <th scope="col" width="10%">{{'Test' | locale}}</th>
                        <th scope="col" width="15%">{{'Parameter' | locale}}</th>
                        <th scope="col" width="5%">{{'Result' | locale}}</th>
                        <th scope="col" width="25%" >{{'Notes' | locale}}</th>
                        <!-- <th scope="col">خيارات</th> -->
                        <!-- <th scope="col"></th> -->
                      </tr>
                    </thead>
                    <tbody class="neue-light">
                      <ng-container
                        *ngFor="
                          let request of historyData
                            | paginate
                              : {
                                  id: 'pagination',
                                  itemsPerPage: pagination.per_page,
                                  currentPage: pagination.current_page,
                                  totalItems: pagination.totalItems
                                }
                        "
                      >
                        <tr
                          valign="middle"
                          class="clickable_row roboto-400"
                          [ngClass]="{ urgent: request.urgent_flag }"
                        >
                          <!-- <td scope="row">{{voucher.serial_number}}</td> -->
                          <td width="15%">{{ request.reception_number }}</td>
                          <!-- <td>{{request.patient_name }}</td> -->
                          <!-- <td>{{request.correspondent_name }}</td> -->
                          <td width="15%">{{ request.date }}</td>
                          <!-- <td width="15%">{{ request.category_name }}</td> -->
                          <td width="10%">{{ request.test_name }}</td>
                          <td width="15%">{{ request.parameter }}</td>
                          <td width="5%">{{ request.result }}</td>
                          <td width="25%">{{ request.note }}</td>

                          <!-- <td style="width: 70px;">{{request.total }} <span class="roboto-400 cur"> &nbsp; LYD</span></td> -->
                          <!-- <td class="flex-column "  ><a class="py-0" ><h5 class="neue-reg text-right">
                          <i (click)="pay(order.id)" *ngIf="order.status == 'بانتظار الدفع'" class="fas fa-dollar-sign item"></i>
                        </h5></a>
                        </td> -->
                          <!-- <td width="15%"><button [disabled]="!(order.order_state_id == 1 || order.order_state_id == 2) " (click)="cancelOrder(order.id)" class=" btn btn-outline-secondary " > الغاء الطلب</button></td> -->
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-12 mt-3">
                <div class="w-100 d-flex justify-content-center neue-med p-0">
                  <pagination-controls
                    pagination-template
                    responsive="true"
                    [previousLabel]="'Previous'| locale"
                    [nextLabel]="'Next'| locale"
                    (pageChange)="getData($event)"
                    id="pagination"
                  ></pagination-controls>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<!--

<div *ngIf="patient" id="patient-content" class="container-fluid py-2">
  <div class="row mt-2 mb-4">
    <div class="col">
      <h4 class="roboto-500">Patient Info</h4>
    </div>
    <div *ngIf="action == 'New'">
      <h5 (click)="patient = null" class="roboto-500 clickable btn btn-sm btn-outline-primary">Change patient</h5>
    </div>
    <div
    *ngIf="this.action == 'Edit' && request.status == 'active'"
    class="ml-2">
      <div class="w-100 justify-content-end d-flex">

        <button
          type="submit"
          disabled
          class="btn btn-outline-danger tajwal-regium px-4"
        >
          Result is not ready
        </button>
      </div>
    </div>
  </div>

    <form >
      <div class="row neue-reg mt-3">
        <div class="col-lg-4">
          <div class="form-group">
            <label for="exampleInputEmail1">First Name</label>
            <input

              required
              pattern="^([^0-9]*)$"
              maxlength="25"
              name="first_name"
              [(ngModel)]="patient['first_name']"
              #name_input="ngModel"
              type="text"
              class="form-control"
            />
          </div>
        </div>

        <div class="col-lg-4">
          <div class="form-group">
            <label for="exampleInputEmail1">Last Name</label>
            <input

              required
              pattern="^([^0-9]*)$"
              maxlength="25"
              [(ngModel)]="patient['last_name']"
              name="last_name"
              type="text"
              class="form-control"
              #last_name_input="ngModel"
            />
          </div>
        </div>

        <div class="col-lg-4">
          <div class="form-group">
            <label for="exampleInputEmail1">Phone number</label>
            <input

              required
              [(ngModel)]="patient['phone_number']"
              name="phone_number"
              type="text"
              class="form-control"
              #last_name_input="ngModel"
            />
          </div>
        </div>

      </div>

      <div class="row neue-reg">
        <div class="col-lg-3">
          <div class="form-group">
            <label for="civility">Civility</label>

            <input
              class="form-control"
              name="civility"

              [(ngModel)]="patient['civility']"
            />

          </div>
        </div>

        <div class="col-lg-3">
          <div class="form-group">
            <label for="civility">Gender</label>

            <input
              class="form-control"
              name="gender"

              [(ngModel)]="patient['gender']"
            />


          </div>
        </div>

        <div class="col-lg-4">
          <div class="form-group">
            <label for="picker">Birthdate</label>
            <input
              class="form-control"
              name="birthdate"

              [ngModel]="patient['birthdate'] | theDate"
            />
          </div>
        </div>

        <div class="col-lg-2">
          <div class="form-group">
            <label for="exampleInputEmail1">Age</label>
            <input
              readonly
              pattern="^(0|[1-9][0-9]*)$"
              maxlength="3"
              [ngModel]="(patient['birthdate'] | ago) ||''"
              name="age"
              type="text"
              class="form-control"
              #age_input="ngModel"
            />

          </div>
        </div>

      </div>
    </form>
</div> -->

<!-- Modal -->
<div
  class="modal fade"
  id="download_upload_modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalCenterTitle">
          {{ download_upload_modal_data.title | locale}}
        </h5>

      </div>
      <div class="modal-body">
        <form #download_form="ngForm" (ngSubmit)="download(download_form)">
          <div class="form-group">
            <label for="reception_number">{{'Request #' | locale}}</label>
            <input
              ngModel
              name="reception_number"
              type="text"
              class="form-control"
              [placeholder]="'Request #' | locale"
            />
          </div>
          <ng-container *ngIf="labs$ | async as labs">
            <div *ngIf="labs.length" class="form-group">
              <label for="lab">{{'Labs' | locale}}</label>
              <select
                [ngModel]="labs[0]"
                name="lab"
                id="lab"
                class="custom-select"
              >
                <ng-container *ngFor="let lab of labs">
                  <option
                    [ngValue]="lab"
                  >
                    {{ lab.name }}
                  </option>
                </ng-container>
              </select>
            </div>
          </ng-container>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{'Close' | locale}}
        </button>
        <button
          (click)="download_form.ngSubmit.emit()"
          type="button"
          class="btn btn-primary"
        >
          {{ download_upload_modal_data.title | locale }}
        </button>
      </div>
    </div>
  </div>
</div>


<div
  class="modal fade"
  id="reasonModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5>{{"Delete Reason" | locale}}</h5>
      </div>

      <div class="modal-body">
        <div class="container-fluid px-4 mt-3">
          <div>
            <input name="reason" id="reason" class="form-control" [placeholder]="'Reason' | locale" [(ngModel)]="reason" #reason_input>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button (click)="addReasons(reason_input)" type="button" class="btn btn-danger">
          {{"Delete" | locale}}
        </button>
      </div>
    </div>
  </div>
</div>
