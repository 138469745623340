<div class="container-fluid">
  <div class="row">
    <div class="col">
      <h2 class="roboto-300 start">{{ "Settings" | locale }}</h2>
    </div>

    <button
      (click)="$event.preventDefault()"
      class="form-control"
      style="width: fit-content;height: fit-content; cursor: inherit"
    >
      <h3 class="m-0">#{{ info["id"] }}</h3>
    </button>
  </div>
  <div class="row">
    <div class="col">
      {{ "Last Update Date" | locale }}: {{ info['last_update_date'] }}
    </div>
  </div>
</div>

<div class="card mb-5 mt-3">
  <div class="container-fluid py-3 px-4">
    <div class="row justify-content-end d-flex flex-row-reverse mt-2 p-2">
      <input
        (change)="upload($event)"
        type="file"
        #logo_input
        style="display: none"
      />

      <div class="mb-3 px-4">
        <div style="width: fit-content" class="mx-auto">
          <label class="">{{ "Logo" | locale }}</label>
          <div
            (click)="logo_input.click()"
            class="text-center clickable img-thumbnail company-logo"
            style="
              height: 180px;
              width: 180px;
              display: flex;
              flex-direction: column;
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
            "
            [ngStyle]="
              (info['logo'] || logo?.url) && !deleted_image
                ? {
                    'background-image':
                      'url(' + (logo.url || info['logo']) + ')',
                    'background-color': 'white'
                  }
                : { 'background-color': 'grey' }
            "
          >
            <i
              *ngIf="!(info['logo'] || logo?.url) || deleted_image"
              style="font-size: 40px"
              class="fas fa-camera text-white text-center my-auto"
            ></i>

            <!-- <span

              class="me-badge btn btn-danger">❌</span> -->
          </div>

        </div>
        <div class="container-fluid px-0">
          <div class="row justify-content-center">
            <ng-container *ngIf="logo?.file || deleted_image">
              <button
                [disabled]="is_updating"
                (click)="update_logo()"
                class="btn-sm m-1 btn-primary col-10"
              >
                {{ "Update" | locale }}
              </button>
              <button
                *ngIf="logo?.file || deleted_image"
                [disabled]="is_updating"
                (click)="
                  logo = { file: null, url: '' };
                  logo_input['value'] = null;
                  deleted_image = false
                "
                class="btn-sm m-1 col-10 btn-light"
              >
                {{ "Restore default" | locale }}
              </button>
            </ng-container>

            <button
              *ngIf="info['logo'] && !logo?.file && !deleted_image"
              [disabled]="is_updating"
              (click)="deleted_image = true"
              class="btn-sm m-1 col-10 btn-danger"
            >
              {{ "Delete" | locale }}
            </button>
          </div>
        </div>
        <!-- <progress style="width: 200px;" *ngIf="file.file"
            class="col-12"
            max="100"
            [value]="file['progress'] || 0"
          ></progress> -->
      </div>

      <input
        (change)="uploadBackground($event)"
        type="file"
        #background_image_input
        style="display: none"
      />

      <div class="mb-3 px-4">
        <div style="width: fit-content" class="mx-auto">
          <label class="">{{ "Background Image" | locale }}</label>
          <div
            (click)="background_image_input.click()"
            class="text-center clickable img-thumbnail company-logo"
            style="
              height: 180px;
              width: 180px;
              display: flex;
              flex-direction: column;
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
            "
            [ngStyle]="
              (info['background_image'] || background_image?.url) && !deleted_background_image
                ? {
                    'background-image':
                      'url(' + (background_image.url || info['background_image']) + ')',
                    'background-color': 'white'
                  }
                : { 'background-color': 'grey' }
            "
          >
            <i
              *ngIf="!(info['background_image'] || background_image?.url) || deleted_background_image"
              style="font-size: 40px"
              class="fas fa-camera text-white text-center my-auto"
            ></i>
          </div>

        </div>
        <div class="container-fluid px-0">
          <div class="row justify-content-center">
            <ng-container *ngIf="background_image?.file || deleted_background_image">
              <button
                [disabled]="is_background_image_updating"
                (click)="update_background_image()"
                class="btn-sm m-1 btn-primary col-10"
              >
                {{ "Update" | locale }}
              </button>
              <button
                *ngIf="background_image?.file || deleted_background_image"
                [disabled]="is_background_image_updating"
                (click)="
                  background_image = { file: null, url: '' };
                  background_image_input['value'] = null;
                  deleted_background_image = false
                "
                class="btn-sm m-1 col-10 btn-light"
              >
                {{ "Restore default" | locale }}
              </button>
            </ng-container>

            <button
              *ngIf="info['background_image'] && !background_image?.file && !deleted_background_image"
              [disabled]="is_background_image_updating"
              (click)="deleted_background_image = true"
              class="btn-sm m-1 col-10 btn-danger"
            >
              {{ "Delete" | locale }}
            </button>
          </div>
        </div>
      </div>

      <div
        class="col-12 col-md"
        [ngClass]="{ 'border-right': !data.isRTL, 'border-left': data.isRTL }"
      >
        <form
          #settings_form="ngForm"
          (ngSubmit)="update_settings(settings_form)"
        >
          <input type="hidden" [(ngModel)]="phones" name="phones" />
          <div class="form-row">
            <div class="form-group col-12 col-md-6">
              <label>{{ "Name" | locale }}</label>
              <input
                [placeholder]="'Name' | locale"
                class="form-control"
                type="text"
                name="name"
                [ngModel]="info['name']"
              />
            </div>

            <div class="form-group col-12 col-md-6">
              <Label>{{ "Currency" | locale }}</Label>
              <input
                [placeholder]="'Currency' | locale"
                class="form-control"
                type="text"
                name="currency"
                [ngModel]="info['currency']"
              />
            </div>
          </div>
          <label>{{ "Phones" | locale }}</label>
          <div class="form-row">
            <div
              *ngFor="
                let phone of phones;
                let i = index;
                trackBy: customTrackBy
              "
              class="form-group col-12 col-md-4"
            >
              <div class="input-group">
                <input
                  [attr.aria-describedby]="'remove_phone_' + i"
                  [placeholder]="('Phone no.' | locale) + '' + (i + 1)"
                  class="form-control"
                  type="text"
                  [name]="'remove_phone_' + i"
                  [(ngModel)]="phones[i]"
                  [ngClass]="{
                    'rad-left': data.isRTL,
                    'rad-right': !data.isRTL
                  }"
                />
                <div
                  (click)="phones.splice(i, 1)"
                  class="input-group-append clickable"
                >
                  <span
                    class="input-group-text border-0"
                    [ngClass]="{
                      'rad-left': !data.isRTL,
                      'rad-right': data.isRTL
                    }"
                    [id]="'remove_phone_' + i"
                    >❌</span
                  >
                </div>
              </div>
            </div>

            <div class="form-group">
              <button
                (click)="phones.push('')"
                class="form-control"
                type="button"
              >
                {{ "Add another phone" | locale }}
              </button>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-12 col-md-6">
              <Label>{{ "Address" | locale }}</Label>
              <input
                class="form-control"
                type="text"
                name="address"
                [placeholder]="'Address' | locale"
                [ngModel]="info['address']"
              />
            </div>

            <div class="form-group col-12 col-md-6">
              <Label>{{ "Logout timer" | locale }}</Label>
              <div class="input-group">
                <input
                  class="form-control"
                  [placeholder]="'Logout timer' | locale"
                  type="text"
                  name="remove_logout-timer"
                  [(ngModel)]="settings['logout-timer']"
                  [ngClass]="{
                    'rad-left': data.isRTL,
                    'rad-right': !data.isRTL
                  }"
                />
                <div class="input-group-append">
                  <span
                    class="input-group-text border-0 border-0"
                    [ngClass]="{
                      'rad-left': !data.isRTL,
                      'rad-right': data.isRTL
                    }"
                    >{{ "Minutes" | locale }}</span
                  >
                </div>
              </div>
            </div>

            <div class="form-group col-12">
              <Label>{{ "Note" | locale }}</Label>
              <textarea
                [placeholder]="'Note' | locale"
                class="form-control"
                name="remove_note"
                [(ngModel)]="settings['note']"
              ></textarea>
            </div>

            <div class="form-group col-12 col-md-6">
              <Label>{{ "Default Pricing" | locale }}</Label>
              <select
                name="remove_defaultPrice"
                class="custom-select"
                [(ngModel)]="settings['defaultPrice']"
              >
                <option *ngFor="let price of data.pricings" [value]="price.id">
                  <!-- <option selected > -->
                  {{ price.name }}
                </option>
              </select>
            </div>

            <!-- <input name="remove_lab-info-header" [(ngModel)]="settings['pdf']['receipt']['lab-info-header']"
              [checked]="settings['pdf']['receipt']['lab-info-header']" type="checkbox" class="form-check-input"
              id="lab-info-header"> -->
            <!-- <div class="form-group col-2">
                <div class="form-check">


                  <input class="form-check-input"
                  [(ngModel)]="settings['isTax']"
                  [checked]="settings['isTax']"
                  name="remove_isTax"
                  type="checkbox">

                  <label class="form-check-label" >
                    Enable
                  </label>
                </div>
              </div> -->

            <div class="form-group col-12 col-md-6">
              <Label>{{ "Tax Value" | locale }}</Label>
              <div class="input-group">
                <input
                  class="form-control"
                  type="number"
                  min="0"
                  max="100"
                  name="remove_tax"
                  placeholder="Tax Value"
                  [(ngModel)]="settings['tax']"
                  [disabled]="!settings['isTax']"
                  [ngClass]="{
                    'rad-left': data.isRTL,
                    'rad-right': !data.isRTL
                  }"
                />
                <div class="input-group-append">
                  <span
                    class="input-group-text border-0"
                    [ngClass]="{
                      'rad-left': !data.isRTL,
                      'rad-right': data.isRTL
                    }"
                  >
                    <div
                      class="form-group form-check start mb-0"
                      style="
                        vertical-align: middle;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <input
                        type="checkbox"
                        class="form-check-input"
                        [(ngModel)]="settings['isTax']"
                        [checked]="settings['isTax']"
                        name="remove_isTax"
                        id="isTax"
                      />
                      <label
                        class="form-check-label"
                        [ngClass]="{ 'mr-4': data.isRTL }"
                        for="isTax"
                        >{{ "Enable" | locale }}</label
                      >
                    </div>
                  </span>
                </div>
              </div>
            </div>

            <div class="form-group col-12 col-lg-4 col-md-6">
              <label>
                {{ "Rounding" | locale }}
              </label>
              <input
                class="form-control"
                [(ngModel)]="settings['financial']['roundPrecision']"
                name="remove_roundPrecision"
                type="input"
                [placeholder]="'Rounding' | locale"
              />
            </div>

            <div class="form-group col-12 col-lg-4 col-md-6">
              <Label>{{ "Technical Result Panel Size" | locale }}</Label>
              <select
                name="remove_technicalResult"
                [(ngModel)]="settings['technicalResult']"
                class="form-control"
                id="technicalResult"
              >
                <option value="8">{{ "Small" | locale }}</option>
                <option value="10">{{ "Medium" | locale }}</option>
                <option value="12">{{ "Large" | locale }}</option>
              </select>
            </div>

            <div class="form-group col-12 col-lg-4 col-md-6">
              <Label>{{ "Confirm Delete Test" | locale }}</Label>
              <select
                name="remove_confirmDeleteTest"
                [(ngModel)]="settings['confirmDeleteTest']"
                class="form-control"
                id="confirmDeleteTest"
              >
                <option [ngValue]="true">{{ "Enable" | locale }}</option>
                <option [ngValue]="false">{{ "Disable" | locale }}</option>
              </select>
            </div>

            <div class="form-group col-12 col-lg-4 col-md-6">
              <Label>{{ "Change role behaviour" | locale }}</Label>
              <select
                name="rolePageInNewTab"
                [(ngModel)]="settings['rolePageInNewTab']"
                class="form-control"
                id="rolePageInNewTab"
              >
                <option [ngValue]="true">{{ "New tab" | locale }}</option>
                <option [ngValue]="false">{{ "Same tab" | locale }}</option>
              </select>
            </div>

            <div class="form-group col-12 col-lg-4 col-md-6">
              <Label>{{ "Default Date in Requests List" | locale }}</Label>
              <select
                name="remove_ReqListDefaultDate"
                [(ngModel)]="settings['ReqListDefaultDate']"
                class="form-control"
                id="ReqListDefaultDate"
              >
                <option [ngValue]="true">{{ "Enable" | locale }}</option>
                <option [ngValue]="false">{{ "Disable" | locale }}</option>
              </select>
            </div>

            <div class="form-group col-12 col-lg-4 col-md-6">
              <Label>{{ "Monthly Renumbering" | locale }}</Label>
                <select name="remove_monthlyRenumbering" [(ngModel)]="settings['monthlyRenumbering']" class="form-control" id="monthlyRenumbering">
                  <option [ngValue]="true">{{ "Enable" | locale }}</option>
                  <option [ngValue]="false">{{ "Disable" | locale }}</option>
                </select>
          </div>

        <div class="form-group col-12 col-lg-4 col-md-6">
          <Label>{{ "Auto Select Gender" | locale }}</Label>
            <select name="remove_selectGender" [(ngModel)]="settings['selectGender']" class="form-control" id="selectGender">
              <option [ngValue]="false">{{ "Enable" | locale }}</option>
              <option [ngValue]="true">{{ "Disable" | locale }}</option>
            </select>
        </div>

        <div class="form-group col-12 col-lg-4 col-md-6">
          <Label>{{ "Print lab to lab requests receipt" | locale }}</Label>
            <select name="remove_selectGender" [(ngModel)]="settings['isLabToLab']" class="form-control" id="isLabToLab">
              <option [ngValue]="true">{{ "Enable" | locale }}</option>
              <option [ngValue]="false">{{ "Disable" | locale }}</option>
            </select>
        </div>
          </div>

          <hr />

          <h4 class="my-3 start">{{ "Receipts" | locale }}</h4>

          <div class="form-group form-check start">
            <input
              type="checkbox"
              class="form-check-input"
              name="remove_lab-info-header"
              [(ngModel)]="settings['pdf']['receipt']['lab-info-header']"
              [checked]="settings['pdf']['receipt']['lab-info-header']"
              id="lab-info-header"
            />
            <label
              class="form-check-label"
              [ngClass]="{ 'mr-4': data.isRTL }"
              for="lab-info-header"
              >{{ "lab info header" | locale }}</label
            >
          </div>

          <div class="form-group form-check start">
            <input
              type="checkbox"
              class="form-check-input"
              name="remove_two-part"
              [(ngModel)]="settings['pdf']['receipt']['two-part']"
              [checked]="settings['pdf']['receipt']['two-part']"
              id="two-part"
            />
            <label
              class="form-check-label"
              [ngClass]="{ 'mr-4': data.isRTL }"
              for="two-part"
              >{{ "Two Parts" | locale }}</label
            >
          </div>

          <div class="form-group form-check start">
            <input
              type="checkbox"
              class="form-check-input"
              name="remove_hideDetails"
              [(ngModel)]="settings['pdf']['receipt']['hideDetails']"
              [checked]="settings['pdf']['receipt']['hideDetails']"
              id="hideDetails"
            />
            <label
              class="form-check-label"
              [ngClass]="{ 'mr-4': data.isRTL }"
              for="hideDetails"
              >{{ "Hide Details For Correspondents Patients" | locale }}</label
            >
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>{{ "Font Size" | locale }}</label>
                <select
                  name="remove_FontSize"
                  [(ngModel)]="settings['pdf']['receipt']['FontSize']"
                  class="form-control"
                  id="FontSize"
                >
                  <option
                    *ngFor="let size of ['small', 'medium', 'large']"
                    [value]="size"
                  >
                    {{ size | titlecase | locale }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <hr />

          <h4 class="start my-3">{{ "Results" | locale }}</h4>

          <div class="form-row">
            <div class="col">
              <!-- <div class="form-row"> -->
              <div class="form-group">
                <label>{{ "Font Size" | locale }}</label>
                <select
                  name="remove_fontSize"
                  [(ngModel)]="settings['pdf']['results']['fontSize']"
                  class="form-control"
                  id="fontSize"
                >
                  <option
                    *ngFor="let size of ['small', 'medium', 'large']"
                    [value]="size"
                  >
                    {{ size | titlecase | locale }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col">
              <!-- <div class="form-row"> -->
              <div class="form-group">
                <label>{{ "Result Alignment" | locale }}</label>
                <select
                  name="remove_resultAlignment"
                  [(ngModel)]="settings['pdf']['results']['resultAlignment']"
                  class="form-control"
                  id="resultAlignment"
                >
                  <option
                    *ngFor="let alignment of ['center', 'left', 'right']"
                    [value]="alignment"
                  >
                    {{ alignment | titlecase | locale }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col">
              <!-- <div class="form-row"> -->
              <div class="form-group">
                <label>{{ "Unit Alignment" | locale }}</label>
                <select
                  name="remove_unitAlignment"
                  [(ngModel)]="settings['pdf']['results']['unitAlignment']"
                  class="form-control"
                  id="unitAlignment"
                >
                  <option
                    *ngFor="let alignment of ['center', 'left', 'right']"
                    [value]="alignment"
                  >
                    {{ alignment | titlecase | locale }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col">
              <!-- <div class="form-row"> -->
              <div class="form-group">
                <label>{{ "Reference Range Alignment" | locale }}</label>
                <select
                  name="remove_referenceRangeAlignment"
                  [(ngModel)]="
                    settings['pdf']['results']['referenceRangeAlignment']
                  "
                  class="form-control"
                  id="referenceRangeAlignment"
                >
                  <option
                    *ngFor="let alignment of ['center', 'left', 'right']"
                    [value]="alignment"
                  >
                    {{ alignment | titlecase | locale }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <Label>{{ "Limit Results Length" | locale }}</Label>
                  <select name="remove_limitResultsLength" [(ngModel)]="settings['limitResultsLength']" class="form-control" id="limitResultsLength">
                    <option [ngValue]="true">{{ "Enable" | locale }}</option>
                    <option [ngValue]="false">{{ "Disable" | locale }}</option>
                  </select>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label>
                  {{ "Rounding" | locale }}
                </label>
                <input
                  class="form-control"
                  [(ngModel)]="settings['resultRound']"
                  name="remove_resultRound"
                  type="input"
                  [placeholder]="'Rounding' | locale"
                />
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <Label>{{ "Healthcare Result Colors" | locale }}</Label>
                  <select name="remove_healthcareResultColors" [(ngModel)]="settings['healthcareResultColors']" class="form-control" id="healthcareResultColors">
                    <option [ngValue]="true">{{ "Enable" | locale }}</option>
                    <option [ngValue]="false">{{ "Disable" | locale }}</option>
                  </select>
              </div>
            </div>
          </div>

          <div class="form-group form-check start">
            <input
              type="checkbox"
              class="form-check-input"
              name="remove_results-lab-info-header"
              [(ngModel)]="settings['pdf']['results']['lab-info-header']"
              [checked]="settings['pdf']['results']['lab-info-header']"
              id="results-lab-info-header"
            />
            <label
              class="form-check-label"
              [ngClass]="{ 'mr-4': data.isRTL }"
              for="results-lab-info-header"
              >{{ "lab info header" | locale }}</label
            >
          </div>

          <div class="form-group form-check start">
            <input
              type="checkbox"
              class="form-check-input"
              name="remove_withDevices"
              [(ngModel)]="settings['pdf']['results']['withDevices']"
              [checked]="settings['pdf']['results']['withDevices']"
              id="withDevices"
            />
            <label
              class="form-check-label"
              [ngClass]="{ 'mr-4': data.isRTL }"
              for="withDevices"
              >{{ "Print devices by default" | locale }}</label
            >
          </div>

          <div class="form-group form-check start">
            <input
              type="checkbox"
              class="form-check-input"
              name="remove_withAreas"
              [(ngModel)]="settings['pdf']['results']['withAreas']"
              [checked]="settings['pdf']['results']['withAreas']"
              id="withAreas"
            />
            <label
              class="form-check-label"
              [ngClass]="{ 'mr-4': data.isRTL }"
              for="withAreas"
              >{{ "Print categories by default" | locale }}</label
            >
          </div>

          <div class="form-group form-check start">
            <input
              type="checkbox"
              class="form-check-input"
              name="remove_withFamily"
              [(ngModel)]="settings['pdf']['results']['withFamily']"
              [checked]="settings['pdf']['results']['withFamily']"
              id="withFamily"
            />
            <label
              class="form-check-label"
              [ngClass]="{ 'mr-4': data.isRTL }"
              for="withFamily"
              >{{ "Print families by default" | locale }}</label
            >
          </div>

          <div class="form-group form-check start">
            <input
              type="checkbox"
              class="form-check-input"
              name="remove_noteBold"
              [(ngModel)]="settings['pdf']['results']['noteBold']"
              [checked]="settings['pdf']['results']['noteBold']"
              id="noteBold"
            />
            <label
              class="form-check-label"
              [ngClass]="{ 'mr-4': data.isRTL }"
              for="noteBold"
              >{{ "Bold Note" | locale }}</label
            >
          </div>

          <hr />
          <h4 class="start my-3">{{ "Reports" | locale }}</h4>

          <div class="form-group form-check start">
            <input
              type="checkbox"
              class="form-check-input"
              name="remove_withCorrespondentId"
              [(ngModel)]="settings['pdf']['report']['withCorrespondentId']"
              [checked]="settings['pdf']['report']['withCorrespondentId']"
              id="withCorrespondentId"
            />
            <label
              class="form-check-label"
              [ngClass]="{ 'mr-4': data.isRTL }"
              for="withDevices"
              >{{ "Print correspondent id by default" | locale }}</label
            >
          </div>

          <hr />
          <h4 class="start my-3">{{ "Email" | locale }}</h4>
          <div class="row"></div>
          <div class="row">
            <div class="form-group col-6">
              <Label>{{ "Username" | locale }}</Label>
              <input
                class="form-control"
                type="text"
                name="remove_username"
                [placeholder]="'Username' | locale"
                [(ngModel)]="email_settings['username']"
              />
            </div>
            <div class="form-group col-6">
              <Label>{{ "Password" | locale }}</Label>

              <div class="input-group">
                <input
                  class="form-control"
                  [type]="show_pass ? 'text' : 'password'"
                  [placeholder]="'Password' | locale"
                  name="remove_password"
                  [(ngModel)]="email_settings['password']"
                  [ngClass]="{
                    'rad-left': data.isRTL,
                    'rad-right': !data.isRTL
                  }"
                />
                <div
                  class="input-group-append clickable"
                  (click)="show_pass = !show_pass"
                >
                  <span
                    class="input-group-text"
                    [ngClass]="{
                      'rad-left': !data.isRTL,
                      'rad-right': data.isRTL
                    }"
                    ><i
                      style="font-size: 24px"
                      class="fas"
                      [ngClass]="{
                        'fa-eye': show_pass,
                        'fa-eye-slash': !show_pass
                      }"
                    ></i
                  ></span>
                </div>
              </div>
            </div>
            <div class="form-group col-3">
              <Label>{{ "Transport" | locale }}</Label>
              <select
                name="remove_transport"
                [(ngModel)]="email_settings['transport']"
                class="form-control"
              >
                <option value="smtp">SMTP</option>
              </select>
            </div>
            <div class="form-group col-3">
              <Label>{{ "Host" | locale }}</Label>
              <input
                class="form-control"
                type="text"
                name="remove_host"
                [(ngModel)]="email_settings['host']"
              />
            </div>
            <div class="form-group col-3">
              <Label>{{ "Encryption" | locale }}</Label>
              <select
                name="remove_encryption"
                [(ngModel)]="email_settings['encryption']"
                class="form-control"
              >
                <option value="ssl">SSL</option>
                <option value="tls">TLS</option>
              </select>
            </div>
            <div class="form-group col-3">
              <Label>{{ "Port" | locale }}</Label>
              <input
                class="form-control"
                [placeholder]="'Port' | locale"
                type="text"
                name="remove_port"
                [(ngModel)]="email_settings['port']"
              />
            </div>
          </div>

          <div class="alert alert-warning start" role="alert">
            {{
              '*If you are using Gmail you have to activate the option "Access the least secure apps".'
                | locale
            }}
          </div>

          <ng-container *ngIf="data.me?.id == 1">
            <hr />

            <label>More Features</label>
            <div class="form-row">
                <div class="form-group col-6 col-lg-6 col-md-6">
                  <Label>{{ "User Units" | locale }}</Label>
                    <select name="remove_userUnits" [(ngModel)]="settings['userUnits']" class="form-control" id="userUnits">
                      <option [ngValue]="true">{{ "Enable" | locale }}</option>
                      <option [ngValue]="false">{{ "Disable" | locale }}</option>
                    </select>
              </div>

              <div class="form-group col-6 col-lg-6 col-md-6">
                <Label>{{ "Reread Parameters In Protocol" | locale }}</Label>
                  <select name="remove_rereadParameterInProtocol" [(ngModel)]="settings['rereadParameterInProtocol']" class="form-control" id="rereadParameterInProtocol">
                    <option [ngValue]="true">{{ "Enable" | locale }}</option>
                    <option [ngValue]="false">{{ "Disable" | locale }}</option>
                  </select>
              </div>
            </div>
            
            <div class="form-row">
                <div class="form-group col-6 col-lg-6 col-md-6">
                  <Label>{{ "Only Message" | locale }}</Label>
                    <select name="remove_onlyMessage" [(ngModel)]="settings['onlyMessage']" class="form-control" id="onlyMessage">
                      <option [ngValue]="true">{{ "Enable" | locale }}</option>
                      <option [ngValue]="false">{{ "Disable" | locale }}</option>
                    </select>
                </div>
            </div>

            <div class="form-row">
              <div class="form-group col-3">
                <label for="exampleInputEmail1"> Abunowas SMS</label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    [(ngModel)]="settings['sms']['abunwas']['status']"
                    [checked]="settings['sms']['abunwas']['status']"
                    name="remove_abunwas-status"
                    type="checkbox"
                  />

                  <label class="form-check-label"> Enable </label>
                </div>
              </div>

              <div class="form-group col-3">
                <Label>Base URL</Label>
                <p></p>
                <div class="input-group">
                  <input
                    class="form-control nrr"
                    type="text"
                    name="remove_abunwas-baseUrl"
                    id="abunwas-baseUrl"
                    placeholder="Base URL"
                    [(ngModel)]="settings['sms']['abunwas']['baseUrl']"
                    [disabled]="!settings['sms']['abunwas']['status']"
                  />
                </div>
              </div>
              <div class="form-group col-3">
                <Label>End Point</Label>
                <p></p>
                <div class="input-group">
                  <input
                    class="form-control nrr"
                    type="text"
                    name="remove_abunwas-end_point"
                    id="abunwas-end_point"
                    placeholder="End Point"
                    [(ngModel)]="settings['sms']['abunwas']['end_point']"
                    [disabled]="!settings['sms']['abunwas']['status']"
                  />
                </div>
              </div>
              <div class="form-group col-3">
                <Label>API Key</Label>
                <p></p>
                <div class="input-group">
                  <input
                    class="form-control nrr"
                    type="text"
                    name="remove_abunwas-api_key"
                    id="abunwas-api_key"
                    placeholder="API Key"
                    [(ngModel)]="settings['sms']['abunwas']['api_key']"
                    [disabled]="!settings['sms']['abunwas']['status']"
                  />
                </div>
              </div>
              <div class="form-group col-3">
                <Label>Secret Key</Label>
                <p></p>
                <div class="input-group">
                  <input
                    class="form-control nrr"
                    type="text"
                    name="remove_abunwas-secret_key"
                    placeholder="Secret Key"
                    [(ngModel)]="settings['sms']['abunwas']['secret_key']"
                    [disabled]="!settings['sms']['abunwas']['status']"
                  />
                </div>
              </div>
              <div class="form-group col-3">
                <Label>Caller ID</Label>
                <p></p>
                <div class="input-group">
                  <input
                    class="form-control nrr"
                    type="text"
                    name="remove_abunwas-callerID"
                    placeholder="Caller ID"
                    [(ngModel)]="settings['sms']['abunwas']['callerID']"
                    [disabled]="!settings['sms']['abunwas']['status']"
                  />
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-3">
                <label for="exampleInputEmail1"> I SEND SMS</label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    [(ngModel)]="settings['sms']['isend']['status']"
                    [checked]="settings['sms']['isend']['status']"
                    name="remove_isend-status"
                    type="checkbox"
                  />

                  <label class="form-check-label"> Enable </label>
                </div>
              </div>

              <div class="form-group col-3">
                <Label>Base URL</Label>
                <p></p>
                <div class="input-group">
                  <input
                    class="form-control nrr"
                    type="text"
                    name="remove_isend-baseUrl"
                    id="isend-baseUrl"
                    placeholder="Base URL"
                    [(ngModel)]="settings['sms']['isend']['baseUrl']"
                    [disabled]="!settings['sms']['isend']['status']"
                  />
                </div>
              </div>
              <div class="form-group col-3">
                <Label>End Point</Label>
                <p></p>
                <div class="input-group">
                  <input
                    class="form-control nrr"
                    type="text"
                    name="remove_isend-end_point"
                    id="isend-end_point"
                    placeholder="End Point"
                    [(ngModel)]="settings['sms']['isend']['end_point']"
                    [disabled]="!settings['sms']['isend']['status']"
                  />
                </div>
              </div>
              <div class="form-group col-3">
                <Label>API Key</Label>
                <p></p>
                <div class="input-group">
                  <input
                    class="form-control nrr"
                    type="text"
                    name="remove_isend-api_key"
                    id="isend-api_key"
                    placeholder="API Key"
                    [(ngModel)]="settings['sms']['isend']['api_key']"
                    [disabled]="!settings['sms']['isend']['status']"
                  />
                </div>
              </div>
              <div class="form-group col-3">
                <Label>Caller ID</Label>
                <p></p>
                <div class="input-group">
                  <input
                    class="form-control nrr"
                    type="text"
                    name="remove_isend-callerID"
                    placeholder="Caller ID"
                    [(ngModel)]="settings['sms']['isend']['callerID']"
                    [disabled]="!settings['sms']['isend']['status']"
                  />
                </div>
              </div>
            </div>

            <hr />

            <label>Mini Bitlims</label>

            <div class="form-row">
              <div class="form-group form-check">
                <input
                  name="remove_mobile"
                  [(ngModel)]="settings['mobile']"
                  [checked]="settings['mobile']"
                  type="checkbox"
                  class="form-check-input"
                />
                <label class="form-check-label">Enable Mobile App</label>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-3">
                <label for="exampleInputEmail1"> Limit Users</label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    [(ngModel)]="settings['limiUsers']"
                    [checked]="settings['limiUsers']"
                    name="remove_limiUsers"
                    type="checkbox"
                  />

                  <label class="form-check-label"> Enable </label>
                </div>
              </div>

              <div class="form-group col-3">
                <Label>Admin Limit</Label>
                <p></p>
                <div class="input-group">
                  <input
                    class="form-control nrr"
                    type="number"
                    min="0"
                    max="100"
                    name="remove_limiUsersNumber"
                    placeholder="Admin Limit"
                    [(ngModel)]="settings['limiUsersNumber']"
                    [disabled]="!settings['limiUsers']"
                  />
                </div>
              </div>
              <div class="form-group col-3">
                <Label>Technician Limit</Label>
                <p></p>
                <div class="input-group">
                  <input
                    class="form-control nrr"
                    type="number"
                    min="0"
                    max="100"
                    name="remove_limiTechNumber"
                    placeholder="Technician Limit"
                    [(ngModel)]="settings['limiTechNumber']"
                    [disabled]="!settings['limiUsers']"
                  />
                </div>
              </div>
              <div class="form-group col-3">
                <Label>Receptionist Limit</Label>
                <p></p>
                <div class="input-group">
                  <input
                    class="form-control nrr"
                    type="number"
                    min="0"
                    max="100"
                    name="remove_limiReceNumber"
                    placeholder="Receptionist Limit"
                    [(ngModel)]="settings['limiReceNumber']"
                    [disabled]="!settings['limiUsers']"
                  />
                </div>
              </div>
            </div>
            <hr />

            <label>Pacakaging</label>
            
            <div class="form-row">
              <div class="form-group form-check">
                <input
                  name="remove_qr"
                  [(ngModel)]="settings['qr']"
                  [checked]="settings['qr']"
                  type="checkbox"
                  class="form-check-input"
                  />
                  <label class="form-check-label">Enable QR</label>
                </div>
              </div>
            
            <div class="form-row">
              <div class="form-group form-check">
                <input
                  name="remove_lab"
                  [(ngModel)]="settings['lab']"
                  [checked]="settings['lab']"
                  type="checkbox"
                  class="form-check-input"
                />
                <label class="form-check-label">Enable Lab To Lab</label>
              </div>
            </div>
            <br>
            
            <div class="form-row">
              <div class="form-group">
                <label for="exampleInputEmail1"> Tickets Number</label>
                <div class="input-group">
                  <input
                  class="form-control nrr"
                  type="number"
                  min="0"
                  name="remove_tickets"
                  placeholder="Tickets Number"
                  [(ngModel)]="settings['tickets']"
                  />
                </div>
              </div>

              <div class="form-group">
                <label for="exampleInputEmail1"> High Tickets Number</label>
                <div class="input-group">
                  <input
                  class="form-control nrr"
                  type="number"
                  min="0"
                  name="remove_highTickets"
                  placeholder="High Tickets Number"
                  [(ngModel)]="settings['highTickets']"
                  />
                </div>
              </div>

              <div class="form-group">
                <label for="exampleInputEmail1"> Medium Tickets Number</label>
                <div class="input-group">
                  <input
                  class="form-control nrr"
                  type="number"
                  min="0"
                  name="remove_mediumTickets"
                  placeholder="Medium Tickets Number"
                  [(ngModel)]="settings['mediumTickets']"
                  />
                </div>
              </div>

              <div class="form-group">
                <label for="exampleInputEmail1"> Low Tickets Number</label>
                <div class="input-group">
                  <input
                  class="form-control nrr"
                  type="number"
                  min="0"
                  name="remove_lowTickets"
                  placeholder="Low Tickets Number"
                  [(ngModel)]="settings['lowTickets']"
                  />
                </div>
              </div>

            </div>

            <!-- <div class="form-row">
              <div class="form-group">
                <label for="exampleInputEmail1"> Tickets Number To Charge</label>
                <div class="input-group">
                  <input
                    class="form-control nrr"
                    type="number"
                    min="0"
                    name="tickets_to_charge"
                    placeholder="Tickets Number To Charge"
                    [(ngModel)]="tickets_to_charge"
                  />
                </div>
              </div>
              
              <div class="form-group col-12 col-lg-4 col-md-6">
                <Label>{{ "Priority" | locale }}</Label>
                <select
                  name="remove_priority"
                  [(ngModel)]="priority"
                  class="form-control"
                  id="priority"
                >
                  <option value="low">{{ "Low" | locale }}</option>
                  <option value="medium">{{ "Medium" | locale }}</option>
                  <option value="high">{{ "High" | locale }}</option>
                </select>
              </div>
              
              <div class="form-group">
                <div class="form-group">
                  <br>
                  <button
                    (click)="rechargeTickets()"
                    type="button"
                    class="btn btn-primary form-control"
                  >
                    {{ "Charge" | locale }}
                  </button>
                </div>
              </div>
            </div> -->
            <div class="form-row">
              <div class="form-group">
                <label for="exampleInputEmail1"> SMS Number</label>
                <div class="input-group">
                  <input
                    class="form-control nrr"
                    type="number"
                    min="0"
                    name="remove_smsNumber"
                    placeholder="SMS Number"
                    [(ngModel)]="settings['smsNumber']"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="form-group">
                  <br>
                  <button
                    (click)="recharge()"
                    type="button"
                    class="btn btn-primary form-control"
                  >
                    {{ "Charge" | locale }}
                  </button>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group">
                <label for="exampleInputEmail1"> SMS Expire</label>
                <div class="input-group">
                  <input
                  id="fromPicker"
                  name="remove_smsExpire"
                  [(ngModel)]="settings['smsExpire']"
                  class="flatpickr flatpickr-input active form-control"
                  type="date"
                  [placeholder]="'SMS Expire' | locale"
                  autocomplete="off"
                  />
                </div>
              </div>
            </div>
            

            <hr />

            <label>Insurance</label>
            
              <div class="row">
                <div class="form-group col-4 col-lg-4 col-md-4">
                  <Label>{{ "Insurance" | locale }}</Label>
                  <select
                    name="remove_insurance"
                    [(ngModel)]="settings['insurance']"
                    class="form-control"
                    id="insurance"
                  >
                    <option [ngValue]="true">{{ "Enable" | locale }}</option>
                    <option [ngValue]="false">{{ "Disable" | locale }}</option>
                  </select>
                </div>
                <div class="form-group col-4">
                  <Label>Insurance Clinic ID</Label>
                  <p></p>
                  <div class="input-group">
                    <input
                      class="form-control nrr"
                      type="text"
                      name="remove_insuranceClinicId"
                      placeholder="Insurance Clinic ID"
                      [(ngModel)]="settings['insuranceClinicId']"
                    />
                  </div>
                </div>
                <div class="form-group col-4">
                  <Label>Insurance Clinic Type</Label>
                  <select
                    name="remove_insuranceClinicType"
                    class="custom-select"
                    [(ngModel)]="settings['insuranceClinicType']"
                  >
                    <option value="sanatoria">Sanatoria</option>
                    <option value="hospital">Hospital</option>
                  </select>
                </div>
              </div>
          </ng-container>
          <div class="form-row">
            <!-- <div class="form-group col-3 col-md-2">
                <button class="btn btn-light form-control">Cancel changes</button>
              </div> -->
            <div class="form-group col-3 col-md-2">
              <button
                [disabled]="settings_form.form.disabled"
                type="submit"
                class="btn btn-primary form-control"
              >
                {{ "Update" | locale }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
